<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Service details" @close-sidebar="closeSidebar">
                <service-details :uuid="product_uuid" @close-sidebar="closeSidebar"></service-details>
            </app-sidebar-details>
        </template>

        <div class="page-fwa-services">
            <app-loader v-if="loading" fixed-on="desktop" />

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="heading">Fixed Wireless Services</h1>
                    </div>

                    <div class="col-12">
                        <app-error v-model="errors.show" :message="errors.message"></app-error>

                        <app-table
                            class="fwa-services-table"

                            title="Fixed Wireless Access"

                            :sort-value="filter.sort"
                            :sort-options="sort"
                            @sort-change="onSortChange"

                            :cols="cols"
                            :rows="rows"

                            :selected-row="product_uuid"

                            @open-sidebar="onToggleServiceDetailsSidebar"
                            @row-click-mobile="onServiceDetails"

                            @remove-service="onRemoveService"
                        />

                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />
                    </div>

                    <div class="col-12">
                        <app-adding-banner-service service="FWA" :tablet-mode="true" />
                    </div>
                </div>
            </div>
        </div>

        <app-dialog-service-removal
            v-if="dialogs.service.remove.show"
            v-model="dialogs.service.remove.show"

            :uuid="dialogs.service.remove.uuid"

            @confirm="onDialogServiceRemovalConfirm"
            @close="onDialogServiceRemovalClose"
        />
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import serviceDetails from '@/views/fwa/service-details'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appAddingBannerService from '@/components/app-adding-banner-service'

import appDialogServiceRemoval from './components/app-dialog-service-removal'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        serviceDetails,

        appLoader,
        appError,
        appTable,
        appPagination,
        appAddingBannerService,

        appDialogServiceRemoval,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc', // asc, desc
                },
            },

            sort: [
                { field: 'State',  title: 'State'  },
                { field: 'IMSI',   title: 'IMSI'   },
                { field: 'MSISDN', title: 'MSISDN' },

                { field: 'CreatedAtNanos', title: 'Created at' },
                { field: 'UpdatedAtNanos', title: 'Updated at' },
            ],

            cols: [
                { key: 'uuid',   title: 'Service UUID', highlight: true, multiline: true },
                { key: 'IMSI',   title: 'IMSI',         sort: { field: 'IMSI'   }        },
                { key: 'MSISDN', title: 'MSISDN',       sort: { field: 'MSISDN' }        },
                { key: 'State',  title: 'State',        sort: { field: 'State'  }        },

                { key: 'CreatedAtNanos', title: 'Created at', sort: { field: 'CreatedAtNanos' } },
                { key: 'UpdatedAtNanos', title: 'Updated at', sort: { field: 'UpdatedAtNanos' } },

                { key: 'remove', action: 'remove-service', icon: 'remove', behavior: 'detached' },
            ],

            products: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            product_uuid: null,

            dialogs: {
                service: {
                    remove: {
                        show: false,
                        uuid: null,
                    },
                },
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        onRemoveService(row) {
            this.dialogs.service.remove.uuid = row.uuid
            this.dialogs.service.remove.show = true
        },

        onDialogServiceRemovalConfirm() {
            if (this.product_uuid == this.dialogs.service.remove.uuid) {
                this.closeSidebar()
            }

            this.getProducts()

            this.dialogs.service.remove.uuid = null
            this.dialogs.service.remove.show = false
        },

        onDialogServiceRemovalClose() {
            this.dialogs.service.remove.uuid = null
            this.dialogs.service.remove.show = false
        },

        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getProducts()
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getProducts()
        },

        onPageChange(page) {
            this.getProducts(page)
        },

        getProducts(page) {
            this.loading = true

            const params = {
                SPID: this.current_spid,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_fwa/FindFWAProductsPaginated', params)
                .then(({ FWAProducts, PageInfo }) => {
                    this.products = FWAProducts

                    this.pagination.page = PageInfo.PageNumber
                    this.pagination.total = PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { page: this.pagination.page },
                            query: this.$route.query,
                            replace: true
                        })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.products = []

                    this.loading = false
                })
        },

        onToggleServiceDetailsSidebar(row) {
            if (this.product_uuid == row.uuid) {
                this.closeSidebar()
            } else {
                this.product_uuid = row.uuid
    
                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.product_uuid = null

            this.$emit('close-sidebar')
        },

        onServiceDetails(row) {
            // this.$router.push({ name: 'fwa-service-details', params: { uuid: row.uuid } })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        rows() {
            const rows = Array.isArray(this.products)
                ? this.products.map(product => ({
                    uuid: product.UUID,
                    IMSI: product.IMSI ? product.IMSI : '?',
                    MSISDN: product.MSISDN ? product.MSISDN : '?',
                    State: product.State ? product.State : '?',

                    CreatedAtNanos: formatDateNano(product.CreatedAtNanos),
                    UpdatedAtNanos: formatDateNano(product.UpdatedAtNanos),

                    remove: {
                        disabled: product.State.toLowerCase() != 'active',
                    },
                }))
                : []

            return rows
        },
    },
}
</script>

<style lang="scss">
.page-fwa-services {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .fwa-services-table {
        @include table-cols-width((275px, 150px, 150px, 150px, 150px, 150px, 48px), true, 1);
    }

    .app-pagination,
    .app-adding-banner-service {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-fwa-services {
        .fwa-services-table {
            @include table-cols-width((275px, 150px, 150px, 150px, 120px, 120px, 48px), true, 1);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-fwa-services {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .fwa-services-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        .app-pagination,
        .app-adding-banner-service {
            margin-top: 15px;
        }
    }
}
</style>