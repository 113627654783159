<template>
    <app-adding-banner class="app-adding-banner-service"
        v-if="is_service_available"

        image="service"

        :header="adding.header"
        :description="adding.description"
        :route="adding.route"

        :tablet-mode="tabletMode"
    />
</template>

<script>
import { mapGetters } from 'vuex'

import appAddingBanner from '@/components/app-adding-banner'

export default {
    props: {
        service: { type: String, required: true }, // "FWA" | "Fibre" | "PostPay Mobile" | "PrePay Mobile"

        tabletMode: { type: Boolean, default: false },

        route: { type: Object, default: () => null },
    },

    components: {
        appAddingBanner,
    },

    computed: {
        ...mapGetters([
            'modules_user',
        ]),

        service_name() {
            return this.service.toLowerCase().split(' ').join('-')
        },

        is_service_available() {
            return this.service_name == 'fwa'            && this.modules_user.PROD_FWA
                || this.service_name == 'fibre'          && this.modules_user.PROD_FIBRE
                || this.service_name == 'postpay-mobile' && this.modules_user.PROD_MOBILE
                || this.service_name == 'prepay-mobile'  && this.modules_user.PREPAY_MOBILE
        },

        adding() {
            return this.is_service_available
                ? {
                    header: `Add ${ this.service } Service`,
                    description: `Easily adds a ${ this.service } Service to your service portfolio`,
                    route: this.route
                        ? this.route
                        : {
                            name: `${ this.service_name }-add-service`
                        }
                }
                : null
        },
    },
}
</script>

<style lang="scss">

</style>