<template>
    <div id="page-fwa-service-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading.service" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'fwa-services' }" v-if="!on_sidebar">Back to Fixed Wireless Services</router-link>

                    <div class="header-info">
                        <div class="status">Provisioning status: <span class="tag" :class="[ status.value ]">{{ status.text }}</span></div>
                    </div>
                </div>
            </div>

            <div class="row details">
                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                >
                    <app-table-details
                        :rows="rows.address"
                        :data="address"

                        title="Address information"
                    />
                </div>

                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                >
                    <app-table-details
                        :rows="rows.information"
                        :data="information"

                        title="Service information"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
    },

    data() {
        return {
            service: null,
            customer: null,

            rows: {
                address: [
                    { key: 'uuid',    title: 'Address ID'              },
                    { key: 'address', title: 'Address text'            },
                    { key: 'profile', title: 'Current Service Profile' },
                ],

                information: [
                    { key: 'color',   title: 'PreQual site colour',    type: 'color' },
                    { key: 'msisdn',  title: 'MSISDN'                                },
                    { key: 'simid',   title: 'SIMID'                                 },
                ],
            },

            loading: {
                service: false,
            },

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            if (this.service_uuid) {
                this.loadServiceDetails()
            }
        },

        loadServiceDetails() {
            this.loading.service = true

            this.$store.dispatch('fwa/getFWAServicesByUUID', { uuid: this.service_uuid }).then(service => {
                this.service = service

                this.loading.service = false
            }).catch(error => {
                console.log('Error fwa/getFWAServicesByUUID', this.service_uuid)
                console.dir(error)

                if (this.on_sidebar) {
                    this.$emit('close-sidebar')
                } else {
                    this.$router.push({ name: 'fwa-services', replace: true })
                }
            })
        },
    },

    watch: {
        service_uuid() {
            if (this.service_uuid) {
                this.loadServiceDetails()
            }
        },
    },

    computed: {
        service_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        status() {
            return this.service ? (
                this.service.Status ? {
                        text: this.service.Status,
                        value: this.service.Status.toLowerCase().replace(/[^\w\d]/g, ''),
                    } : {
                        text: 'Unknown',
                        value: 'info',
                    }
                ) : {
                    text: 'Loading',
                    value: 'info',
                }
        },

        address() {
            return this.service && this.service.Address ? {
                uuid: this.service.Address.UUID || '?',
                address: this.service.Address.FormattedAddress || '?',
                profile: this.service.Address.ServiceProfile || '?',
            } : {
                uuid: '-',
                address: '-',
                profile: '-',
            }
        },

        information() {
            return this.service ? {
                color: this.service.Colour,
                msisdn: this.service.MSISDN || '?',
                simid: this.service.SIMID || '?',
            } : {
                color: '-',
                msisdn: '-',
                simid: '-',
            }
        },
    },
}
</script>

<style lang="scss">
#page-fwa-service-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;

        .status {
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: 16px;
            line-height: 24px;
            color: var(--color-text-default);

            .tag {
                margin-left: 16px;
                padding: 4px 12px;
                font-weight: bold;
                text-align: center;
                border-radius: $border-radius-primary;
                
                @each $type in $table-tag-types {
                    &.#{ $type } {
                        color: map-get($table-tag-colors, $type );
                        background-color: map-get($table-tag-backgrounds, $type );
                    }
                }
            }
        }
    }

    .col-12 {
        margin-top: 30px;

        &:first-child { margin: 0 };
    }
}

@media (max-width: $tablet-size) {
    #page-fwa-service-details {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;

            .status {
                font-size: 14px;
                line-height: 24px;
                margin-left: 16px;
                white-space: nowrap;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-fwa-service-details {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;

            .status {
                margin: 8px 0 0;
                justify-content: flex-start;

                .tag {
                    margin-left: 12px;
                    line-height: 20px;
                }
            }
        }

        .details {
            .col-mob-12 {
                margin-top: 15px;

                &:first-child { margin: 0; }
            }
        }
    }
}
</style>